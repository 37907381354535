import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// eslint-disable-next-line import/named
import {
  goDirectToManuals,
  goToManualsSearch,
  goToPaperlessPage,
} from '@/utils/Common/Redirects';

// import api
import apiManualsByTerm from '@/api/get/manualByTerm';

// import hooks
import useDebounce from '@/hooks/useDebounce';

// import components
import InputAutocomplete from '@/components/atoms/InputAutocomplete';
import { isTrue } from '@/utils/Common/Booleans';

const AutocompleteManual = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleOnChange = e => {
    const { value } = e.currentTarget;
    setSearchTerm(value);
  };

  const handleOnBlur = () => {
    setSuggestions([]);
  };

  const handleOnClickSuggestion = code => {
    if (isTrue(DLG.config.paperlessEnabled)) {
      goToPaperlessPage(code);
    } else {
      goDirectToManuals(code);
    }
  };

  const handleSubmit = () => {
    if (searchTerm !== '') {
      goToManualsSearch(searchTerm);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      apiManualsByTerm({
        term: debouncedSearchTerm,
        successCB: setSuggestions,
      });
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <InputAutocomplete
      id='manual-search'
      label=''
      modifiers={['searchBar']}
      value={searchTerm}
      suggestions={suggestions}
      suggestionKey={
        isTrue(DLG.config.paperlessEnabled) ? 'baseProduct' : 'url'
      }
      suggestionValue='name'
      suggestionUniqKey='code'
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onClickSuggestion={handleOnClickSuggestion}
      wButton
      onSubmit={handleSubmit}
    />
  );
};

export const AutocompleteManualStandalone = () => {
  const el = document.getElementById('js-r-entry-manual-search');
  ReactDOM.render(<AutocompleteManual />, el);
};

export default AutocompleteManual;
